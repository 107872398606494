import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../pages/Login";
import Recuperar from "../pages/Login/Recuperar";

const AuthRoutes = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/recuperar" element={<Recuperar />} />
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

export default AuthRoutes;
