import { api } from "../../api";
import { EmployeeOutsourced } from "./types";

const byId = (id?: number) => api.get(`funcionarioTerceiro/${id}`);

const findAll = () => api.get(`funcionarioTerceiro`);

const remove = (id: number) => api.delete(`funcionarioTerceiro/${id}`);

const updateStatus = (id: number) =>
	api.put(`funcionarioTerceiro/alterarStatus/${id}`);

const create = (payload?: EmployeeOutsourced) =>
	api.post("funcionarioTerceiro", payload);

const put = (payload?: EmployeeOutsourced) =>
	api.put(`funcionarioTerceiro/${payload?.id}`, payload);

export const employeeOutsourced = {
	byId,
	findAll,
	remove,
	updateStatus,
	create,
	put,
};
