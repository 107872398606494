import { createTheme } from "@mui/material/styles";
export const theme = createTheme({
  palette: {
    secondary: {
      main: "#FFF",
    },
    primary: {
      main: "#133777",
    },
  },
});
