import axios from "axios";

export const api = axios.create({
  baseURL: "https://meritosapi.schmersalbr.com/",
});

export const HTTTP_STATUS = Object.freeze({
  PENDING: "PENDING",
  FULLFILED: "FULLFILED",
  REJECTED: "REJECTED",
});
