import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiCircularProgress-colorSecondary {
    color: #133777;
  }
  img {
    width: 200px;
    margin-right: 2rem;
  }
`;
