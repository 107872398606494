import SearchIcon from "@mui/icons-material/Search";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useEffect, useState } from "react";
import TableListData from "../../components/TableData";
import { logs } from "../../service/requests/logs";
import { ModelPayload } from "../../service/requests/logs/types";
import { reversal } from "../../service/requests/reversal";
import {
  Container,
  DatePicker,
  InputDate,
  InputDiv,
  LabelDate,
  SearchButton,
} from "./styles";
import { History } from "./types";
import { toastfyError, toastfySuccess } from "../../components/Toastfy";
import { CsvBuilder } from "filefy";

export const Historico = () => {
  const [collection, setColletion] = useState<History[]>();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleSearch = (startDate: string, endDate: string) => {
    let dataInicio = startDate;
    let dataFim = endDate;

    if (dataInicio.length !== 10) {
      dataInicio = "";
    }

    if (dataFim.length !== 10) {
      dataFim = "";
    }

    const params: ModelPayload = {
      dataInicio: dataInicio,
      dataFim: dataFim,
    };

    logs.findByDate(params).then((response) => {
      const { data } = response;
      setColletion(data);
    });
  };

  const handleReversal = (rowData: History) => {
    reversal
      .novoEstorno(rowData.idMeritoDistribuido)
      .then(() => {
        toastfySuccess("Operação estornada com sucesso!");
        handleSearch(startDate, endDate);
      })
      .catch(() => {
        toastfyError("Erro ao estornar!");
      });
  };

  useEffect(() => {
    logs.findAll().then((response) => {
      const { data } = response;
      setColletion(data);
    });
  }, []);

  return (
    <Container>
      <DatePicker>
        <InputDiv>
          <LabelDate>Data Início: </LabelDate>
          <InputDate
            type="date"
            onChange={(e) => setStartDate(e.target.value)}
          />
        </InputDiv>
        <InputDiv>
          <LabelDate>Data Fim: </LabelDate>
          <InputDate
            min={startDate}
            type="date"
            onChange={(e) => setEndDate(e.target.value)}
          />
        </InputDiv>
        <SearchButton onClick={() => handleSearch(startDate, endDate)}>
          <p style={{ marginLeft: "4px", fontSize: "12px" }}>Buscar</p>
          <div
            style={{
              display: "flex",
              backgroundColor: "rgba(0, 37, 133, 0.1)",
              height: "100%",
              alignItems: "center",
            }}
          >
            <SearchIcon
              style={{ padding: "10px", color: "rgba(255, 255, 255, 1)" }}
              fontSize="small"
            />
          </div>
        </SearchButton>
      </DatePicker>
      <TableListData
        title="Histórico"
        data={collection || []}
        columns={[
          { title: "Data da Movimentação", field: "data" },
          { title: "Doador", field: "quemDoou" },
          { title: "Receptor", field: "quemRecebeu" },
          { title: "Tipo Mérito", field: "descTipoMerito" },
          { title: "Quantidade", field: "quantidade" },
          { title: "Tipo Operação", field: "descTipoOperacao" },
          { title: "Departamento", field: "descDepartamento" },
          { title: "Motivo", field: "motivo", hidden: true },
        ]}
        options={{
          actionsColumnIndex: -1,
          exportAllData: true,
          exportCsv: (data, columns) => {
            const columnTitles = data.map((column) => column.title);

            const csvData = columns.map((rowData) =>
              data.map((columnDef) => rowData[columnDef.field])
            );

            const builder = new CsvBuilder(`MeritosSchmersalHistory.csv`)
              .setDelimeter(";")
              .setColumns(columnTitles)
              .addRows(csvData)
              .exportFile();

            return builder;
          },
        }}
        style={{
          color: "#133777",
        }}
        actions={[
          (rowData) => ({
            icon: () => <KeyboardReturnIcon />,
            tooltip: "Estornar operação",
            onClick: (event, rowData) => {
              handleReversal(rowData);
            },
            disabled:
              rowData.descTipoOperacao === "Estorno" ||
              rowData.descTipoOperacao === "Resgate" ||
              rowData.descTipoOperacao === "Doação" ||
              rowData.estornado === true,
          }),
        ]}
      />
    </Container>
  );
};
