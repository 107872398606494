export const styles = {
  borderRadius: "10px",
  width: "180px",
  marginLeft: "80%",
  height: "42px",
  border: "solid",
  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
  borderColor: "#133777",
  borderWidth: "0.5px",
  color: "#133777",
  textTransform: "none",
};
